<template>
  <div class="px-4 pdf-preview">
    <!-- Header Section -->
    <div class="c-card u-p-small u-mb-small mx-2 pb-2 pt-3 sticky-card">
      <div class="row position-relative px-3 align-items-center">
        <h4 class="mb-1">PDF Preview</h4>
        <div class="ml-auto">
          <a :href="url" download @click.prevent="downloadPDF" size="md" class="btn c-btn-outlined-info"> <i class="fa  fa-file-pdf-o mr-2"></i> Download </a>
          <a target="_blank" :href="url" @click.prevent="onOpenInWord" size="md" class="btn c-btn-outlined-info mx-3">
            <i class="fa fa-file-word-o u-mr-xsmall"></i>
            Open in MS-Word
          </a>
          <b-button @click="onPrint" size="md" class="ss-info-bg border-0">
            <i class="fa fa-print u-mr-xsmall"></i>
            Print
          </b-button>
        </div>
        <!-- <p class="mb-1 mr-2">
            <strong>{{ headerSubTitle }}</strong>
          </p> -->
      </div>
    </div>

    <div class="c-card u-p-small u-mb-small mx-2 pt-3 pdf-container h-100vh">
      <LoadingScreen v-if="loading" />
      <div v-if="!loading && url">
        <vue-pdf-app theme="dark" :config="config" :pdf="url" @open="openHandler"> </vue-pdf-app>
      </div>
    </div>
  </div>
</template>

<script>
  import VuePdfApp from "vue-pdf-app";
  import LoadingScreen from "@/components/_universal/global/loading-screen.vue";
  import "vue-pdf-app/dist/icons/main.css";
  import print from "print-js";
  import axios from "axios";

  const getSidebar = () => ({
    viewThumbnail: true,
    viewOutline: true,
    viewAttachments: true,
  });
  export default {
    name: "PreviewPDF",
    components: {
      VuePdfApp,
      LoadingScreen,
    },
    data() {
      return {
        loading: true,
        url: null,
        wordURL: null,
        documentName: null,
        config: {
          toolbar: false,
          sidebar: getSidebar(),
        },
      };
    },
    created() {
      if (this.$route && this.$route.query && this.$route.query.url) {
        this.url = this.$route.query.url;
        this.wordURL = this.$route.query.wordURL;
        this.documentName = this.$route.query.name;
        this.loading = false;
      }
    },
    methods: {
      openHandler(pdf) {
        let outerContainerHtml = document.getElementById("outerContainer");
        outerContainerHtml.classList.add("sidebarOpen");
        if (pdf && !pdf.pdfSidebar.isOpen) {
          this.config.sidebar = getSidebar();
          pdf.pdfSidebar.isOpen = true;
        }
      },
      async downloadPDF() {
        const response = await axios.get(this.url, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.documentName;
        link.click();
      },
      // downloadPDF() {
      //   // window.open(this.url, "_blank");
      //   let link = document.createElement("a");
      //   link.href = this.url;
      //   link.download = "file.pdf";
      //   document.body.appendChild(link);
      //   this.$nextTick(() => {
      //     link.click();
      //     document.body.removeChild(link);
      //   });
      // },
      onOpenInWord() {
        if (!this.wordURL) return;
        var lnk = "ms-word:ofe|u|" + this.wordURL + "|a|Web";
        window.location.href = lnk;
      },
      onPrint() {
        if (!this.url) return;
        printJS(this.url);
      },
    },
  };
</script>
<style scoped lang="scss">
  .pdf-preview {
    height: 85vh;
    .pdf-container > div {
      height: 100%;
    }
  }
</style>
